import React from 'react'
import "../styles/OnlineBooking.css";
import background from "../assets/AboutTestBackground2.jpg";
import { Button } from '@mui/material';

function OnlineBooking() {
  return (
    <div className="OnlineBooking">

      <div className="top">
        <h1>Online Booking</h1>
          <p>Online appointment booking is only available to current patients of Dr. Alice Wang. Please read the following instructions carefully for appointments available to book online. If you do not see your appointment available, you must call the office at 519-632-9153 to book an appointment. You cannot book same day appointments online - you must call the office at 519-632-9153. 
            Inappropriately booked appointments may result in this feature being removed. 
          </p>
      </div>

      <div className="bottom">
        <div className="left">
          <h1>Nurse</h1>
          <p>
            <p style = {{fontWeight: "700"}}>Vaccination (i.e.)</p>
            <ul class="custom-list">
              <li>Flu vaccine</li>
              <li>Pneumovax, Prevnar</li>
              <li>Tetanus, diphtheria, pertussis</li>
              <li>Please note we do not stock all vaccines in the office, you are required to bring some vaccinations (such as Twinrix) to our office. Please contact us to ensure your vaccination is in stock. </li>
            </ul> 
            <p style = {{fontWeight: "700"}}>Medical Injection</p>
            <ul class="custom-list">
              <li>Prolia (every 6 months)</li>
              <li>Vitamin B12</li>
              <li>Allergy shots</li>
            </ul>
            <p style = {{fontWeight: "700"}}>Suture/Staple Removal</p>
            <p style = {{fontWeight: "700"}}>Pap Visit</p>
            <p style = {{fontWeight: "700"}}>Blood Pressure Check</p>
            <p style = {{fontWeight: "700"}}>Ear Syringing</p>
            <ul class="custom-list">
              <li>Must be referred by practitioner first to schedule an appointment</li>
              <li>Requires patient to apply oil of choice (olive oil, baby oil etc.) 3 days prior twice a day to soften the cerumen (ear wax)</li>
            </ul>
            </p>
          <a href="https://ocean.cognisantmd.com/intake/patients.html?linkRef=bb23ef5a-60b4-46cf-80c5-59eda143f295#/online-booking" target="_blank" rel="noreferrer">
            <Button className="button" variant="contained" style = {{backgroundColor: "white", color: "black", marginBottom: "20px"}}>
              Book Online
            </Button>
          </a>
        </div>

        <div className="middle">
          <h1>Dr. Alice Wang</h1>
          <p className="instructions">Onling booking is appropriate for <b>appointment types below</b> and for <b>single issues only</b>. If you have more than 1 concern to discuss and for appointment types not listed below, you must call the office at <b>519-632-9153</b> to book an appointment.</p>
          <p style = {{fontWeight: "700"}}>Diabetes Visit</p>
          <ul class="list">
            <li>You must have recently completed bloodwork to book this appointment</li>
          </ul>
          <p style = {{fontWeight: "700"}}>Mental Health Visit</p>
          <ul class="list">
            <li>A brief assessment of mental and emotional wellbeing can help identify warning signs of treatable ailments, if caught early</li>
            <li>Changes in mood or behavior</li>
            <li>Medication follow-up</li>
          </ul>
          <p style = {{fontWeight: "700"}}>Liquid Nitrogen (Treatment Only)</p>
          <ul class="list">
            <li>Liquid nitrogen treatment for previously treated lesions, there may be a fee associated with liquid nitrogen treatment</li>
            <li>All new lesions will require a new assessment </li>
          </ul>
          <p style = {{fontWeight: "700"}}>Ministry of Transportation Driver’s Physical</p>
          <ul class="list">
            <li>You must bring the Ministry of Transportation form with you to the appointment </li>
          </ul>
          
          <a href="https://ocean.cognisantmd.com/intake/patients.html?linkRef=cfc51fe3-b5bf-4904-82ac-e9e484a915f3#/online-booking" target="_blank" rel="noreferrer">
            <Button className="button" variant="contained" style = {{backgroundColor: "black", color: "white", marginBottom: "20px"}}>
              Book Online
            </Button>
          </a>
        </div>

        <div className="right">
          <h1>Nurse Practitioner</h1>
          <p className="NP">Online Booking Coming Soon</p>
          
        </div>
      </div>
    </div>
  )
}

export default OnlineBooking
