import React from 'react'
import background from "../assets/AboutTestBackground2.jpg";
import "../styles/Contact.css";
import Break from "../components/Break"

import PhoneIcon from '@mui/icons-material/Phone';
import FaxIcon from '@mui/icons-material/Fax';
import EmailIcon from '@mui/icons-material/Email';
import PlaceIcon from '@mui/icons-material/Place';
import MapIcon from '@mui/icons-material/Map';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

function Contact() {
  return (
    <div className="Contact">

      <div className="contactTop" style={{ backgroundImage: `url(${background})` }}> 
      </div>

      <div className="contactBottom" > 

        <div className="left">
          <div className="map">
            <iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=45%20Stanley%20Street,%20Ayr,%20ON+(AW%20Family%20Med)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/population/">Population mapping</a></iframe>
          </div>
        </div>

        <div className="middle">
          <h1>Locate Us</h1>
          <p><PlaceIcon style={{ verticalAlign: 'middle', marginRight: '5px' }}/>Address: Suite 207, 45 Stanley Street</p>
          <p><MapIcon style={{ verticalAlign: 'middle', marginRight: '5px' }}/>Ayr, Ontario, N0B 1E0</p>
        </div>
        
        <div className="right">
          <h1>Contact Us</h1>
          <p><PhoneIcon style={{ verticalAlign: 'middle', marginRight: '5px' }}/>Phone: (519) 632-9153</p>
          <p><FaxIcon style={{ verticalAlign: 'middle', marginRight: '5px' }}/>Fax: (519) 632-9578</p>
          <p><AccessTimeFilledIcon style={{ verticalAlign: 'middle', marginRight: '5px' }}/>M/T/T 9-4PM</p>
          <p><AccessTimeFilledIcon style={{ verticalAlign: 'middle', marginRight: '5px' }}/>W/F 9-12PM</p>
        </div>

      </div>
    </div>
  )
}

export default Contact
