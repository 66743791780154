import React from 'react'
import {Link} from "react-router-dom";
import "../styles/Body.css";
import { Button } from '@mui/material';
import HomeBackground from "../assets/HomeBackground3.png"


function Body() {
  return (
    <div className="Body">

        <div className="leftSide">
            
        </div>

        <div className="rightSide">

            <div className="top">
                <h3>Join Our Waitlist (Coming Soon)</h3>
                <p>We are currently not accepting new patients. You are welcome to register for a spot on our waitlist once a spot becomes available. 
                    If you are registering multiple family members, please list all family members. 
                </p>
                <br/>
                {/* <a href="https://www.example.com/" target="_blank" rel="noreferrer">
                    <Button variant="contained" style = {{backgroundColor: "white", color: "black"}} >Sign Up For Our Waitlist</Button>
                </a> */}
            </div>
                
            <div className="bottom">
                <h3>Online Booking</h3>
                <p>
                    We are pleased to offer online appointment booking for current patients. Please read the instructions carefully as only select appointment types are available.
                </p>
                {/* <Link to="/online-booking"> 
                    <Button variant="contained">
                        Click to Learn More
                    </Button>
                </Link> */}
                <br/>
                <a href="/online-booking">
                    <Button variant="contained" style = {{backgroundColor: "white", color: "black"}}>Book an Appointment Online</Button>
                </a>
                
            </div>

        </div>
      
    </div>
  )
}

export default Body
