import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import './App.css';

import Home from "./pages/Home";
import About from "./pages/About";
import OnlineBooking from "./pages/OnlineBooking";
import Contact from "./pages/Contact";

import Navbar from "./components/Navbar.js";
import Footer from "./components/Footer.js";


function App() {
  return (
    <div className="App">
      <Router>
        <Navbar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/online-booking" element={<OnlineBooking />} />
          <Route path="/contact-us" element={<Contact />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;