import React from 'react'
import "../styles/Home.css"
import Body from "../components/Body"
import Info from "../components/Info"
import Info2 from "../components/Info2"
import Break from "../components/Break"


function Home() {
  return (
    <div className="Home">
      <div classname="Body">
        <Body/>
      </div>
      
      <div className="Info">
        <Info/>
      </div>

      <div className="Info2">
        <Info2/>
      </div>
    </div>
  )
}

export default Home
