import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import "../styles/Navbar.css";
import { Button } from '@mui/material';
import ReorderIcon from '@mui/icons-material/Reorder';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import BookIcon from '@mui/icons-material/Book';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';


function Navbar () {

  const [showLinks, setshowLinks] = useState(false);

  const toggleNavbar = () => {
    setshowLinks(!showLinks);
  };

  let menuRef = useRef();

  useEffect(() => {
    let handler = (e)=>{
      if(menuRef.current && !menuRef.current.contains(e.target)){
        setshowLinks(false);
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  return (
      <div className="navbar">
        <div className="leftSide">
          <p className="text" >
              Dr.&nbsp;Alice Wang,&nbsp;MD CCFP 
          </p>
          {/* <div className="hiddenLinks"> 
            <Link to="/"> Home </Link>
            <Link to="/about"> About Us </Link>
            <Link to="/online-booking"> Online Booking </Link>
            <Link to="/contact-us"> Contact Us </Link>
          </div> */}
        </div>

        <div className="rightSide"> 
          <Link to="/"> Home </Link>
          <Link to="/about"> About Us </Link>
          <Link to="/online-booking"> Online Booking </Link>
          <Link to="/contact-us"> Contact Us </Link>
          <Button onClick={toggleNavbar}>
            <ReorderIcon/>
          </Button>
        </div>
        {showLinks && (
          <div className="DropdownMenu" ref={menuRef}>
            <div className="header">
            <p style={{paddingfontSize:"20px"}}>
              <Button onClick={toggleNavbar} style={{color: "black"}}>
                <CloseIcon style={{ verticalAlign: 'middle', marginRight: '5px' }}/>
              </Button>Dr.&nbsp;Alice Wang,&nbsp;MD CCFP</p>
            </div>
            <div className="links">
                <Link to="/" onClick={toggleNavbar}> 
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',}}>
                    <HomeIcon style = {{marginRight: "5px"}}/>
                    <span>Home</span>
                </div>   
                </Link>
                <Link to="/about" onClick={toggleNavbar}> 
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',}}>
                    <InfoIcon style = {{marginRight: "5px"}}/>
                    <span>About Us</span>
                </div>   
                </Link>
                <Link to="/online-booking" onClick={toggleNavbar}> 
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',}}>
                    <BookIcon style = {{marginRight: "5px"}}/>
                    <span>Online Booking</span>
                </div>   
                </Link>
                <Link to="/contact-us" onClick={toggleNavbar}> 
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',}}>
                    <PersonPinCircleIcon style = {{marginRight: "5px"}}/>
                    <span>Contact Us</span>
                </div>   
                </Link>
            </div>
        </div>
        )}
      </div>
  )
}

export default Navbar