import React from 'react'
import "../styles/Info2.css";

function Info() {
  return (
    <div className="Info2">
        <div className="text">
            <h1>Latest News</h1>
            <p>Please check here for the latest news.</p>
            <p> The office will be closed for Christmas and Boxing Day, December 25th and 26th 2023. </p>
            <p> Flu shots are available now at our office. </p>
        </div>
    </div>
  )
}

export default Info