import React from 'react'
import background from "../assets/AboutTestBackground2.jpg";
import "../styles/About.css";
import PrimacareLogo from "../assets/PrimacareLogo.png";
import Break from "../components/Break"

function About() {
  return (
    <div className="about">
      <div className="aboutTop" style={{ backgroundImage: `url(${background})` }}> 
      </div>

      <div className="aboutBottom" > 
        <div className="paragraph">
          <p> 
          Dr. Alice Wang is a family physician who works in Ayr, Ontario. The office is affiliated with PrimaCare Family Health Team and offers team-based primary care along with nurses, nurse practitioners, physician assistants and other allied health professionals.
          Through the family health team, we are about to offer various services, including:
          </p>
        </div>
        <div className="list">
          <p>Chiropody</p>
          <p>Dietician</p>
          <p>Pharmacist consultations</p>
          <p>Counseling</p>
          <p>INR clinic</p>
          <p>Blood pressure clinic</p>
        </div>
          <p className="paragraph">Please visit the <a href="https://www.pccfht.ca/" target="_blank" rel="noreferrer">Primacare Website</a> for more details.</p>
      </div>
      <img className="PClogo" src={PrimacareLogo} alt="PrimacareLogo"/>

      
    </div>
  )
}

export default About