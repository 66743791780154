import React from 'react'
import "../styles/Info.css";
import { Button } from '@mui/material';

function Info() {
  return (
    <div className="Info">
        <div className="text">
            <h1>Information About After Hours Care</h1>
            <p>If you are in need of medical care after hours and/or the office is closed, please call PrimaCare at 519-442-9834 to book an after hours appointment or use the Willett Urgent Care in Paris. If it is an emergency, please go to the nearest hospital or call 9-11. Our After Hours Clinic is located at 25 Curtis Ave. North Suite 201, Paris, Ontario N3L 3W1. 
              When walk-in clinics are used, we do not always get your records/investigations and are often unaware you have been seen unless notified. Your doctor is financially penalised for the use of walk-in clinics. 
              &nbsp; 
            </p>
            {/* <a href="https://www.example.com/" target="_blank" rel="noreferrer">
              Primacare Website
            </a>
            <p>.</p> */}
        </div>
    </div>
  )
}

export default Info
